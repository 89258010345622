import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { useTranslation } from 'react-i18next';
import { getIsFoodCourt } from 'redux/app';
import { useAppSelector } from 'redux/store';
import { getAccessToken } from 'redux/customer/customer.slice';

export const useAccountButton = () => {
    const isAuthenticated = useAppSelector(getAccessToken);
    const isFoodCourt = useAppSelector(getIsFoodCourt);

    const { t } = useTranslation();
    const { navigateToAccount } = useCustomNavigate();

    return {
        navigateToAccount,
        shouldDisplayButton: !!isAuthenticated && !isFoodCourt,
        ariaLabel: t('account_button'),
    };
};
