import { TranslationKey } from 'locales/fr';
import React, { ReactElement, useCallback } from 'react';
import { Carret, Radio, Text } from 'components';
import { SelectionSummary, SummarySelectedItem } from 'components/SelectionSummary';
import {
    Container,
    OptionContainer,
    OptionContent,
    OptionIconsContainer,
    OptionNameContainer,
    OptionNameContent,
    SummaryContainer,
} from './RadioSelect.style';

type BaseSelectOptions<T> = {
    value: T;
    icon?: React.FunctionComponent;
    isStep?: boolean;
};

type ConditionalSelectOptions =
    | {
          name: TranslationKey;
          description?: TranslationKey;
          descriptionContent?: React.ReactNode;
          hasLineBreakBeforeDescription?: boolean;
          noTranslation?: false;
          disabled?: boolean;
          error?: TranslationKey;
      }
    | {
          name: string;
          description?: string;
          descriptionContent?: React.ReactNode;
          hasLineBreakBeforeDescription?: boolean;
          noTranslation: true;
          disabled?: boolean;
          error?: TranslationKey;
      };

export type SelectOptions<T> = BaseSelectOptions<T> & ConditionalSelectOptions;

export type RadioSelectProps<T> = {
    options: SelectOptions<T>[];
    onChange: (value: T | undefined) => void;
    selected?: T;
    canUnselect?: boolean;
    selectedSummary?: Record<number, SummarySelectedItem[]>;
    missingSteps?: Record<number, number>;
};

export const RadioSelect = <T,>({
    options,
    onChange,
    selected,
    canUnselect,
    selectedSummary,
    missingSteps,
}: RadioSelectProps<T>): ReactElement => {
    const handleOnChange = useCallback(
        (value: T) => {
            const newValue = selected === value && canUnselect ? undefined : value;
            onChange(newValue);
        },
        [selected, canUnselect, onChange],
    );

    return (
        <Container data-testid="radio-select">
            {options.map(
                ({
                    name,
                    value,
                    icon: Icon,
                    noTranslation,
                    isStep,
                    error,
                    description,
                    descriptionContent,
                    hasLineBreakBeforeDescription,
                    disabled,
                }) => {
                    const isChecked = selected === value;
                    const selectedSummaryItems = selectedSummary?.[Number(value)];
                    const missingItemCount = missingSteps?.[Number(value)];

                    return (
                        <OptionContainer key={name} disabled={disabled}>
                            <OptionContent onClick={() => !disabled && handleOnChange(value)}>
                                <OptionNameContainer>
                                    {Icon && <Icon data-testid="icon" />}
                                    <OptionNameContent
                                        isMultiline={!!hasLineBreakBeforeDescription}
                                    >
                                        {noTranslation ? (
                                            <>
                                                <Text text={name} type={2} noTranslation />
                                                {description && !descriptionContent && (
                                                    <Text
                                                        text={description}
                                                        type={2}
                                                        noTranslation
                                                    />
                                                )}
                                                {!description && descriptionContent && (
                                                    <>{descriptionContent}</>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Text text={name} type={2} />
                                                {description && !descriptionContent && !error && (
                                                    <Text text={description} type={2} />
                                                )}
                                                {!description && descriptionContent && !error && (
                                                    <>{descriptionContent}</>
                                                )}
                                                {error && (
                                                    <Text
                                                        color="errorColor"
                                                        text={error}
                                                        type={2}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </OptionNameContent>
                                </OptionNameContainer>

                                <OptionIconsContainer>
                                    <Radio checked={isChecked} disabled={disabled} />
                                    {isStep && <Carret />}
                                </OptionIconsContainer>
                            </OptionContent>

                            {isChecked && (selectedSummaryItems || !!missingItemCount) && (
                                <SummaryContainer>
                                    <SelectionSummary
                                        selectedItems={selectedSummaryItems || []}
                                        missingItemCount={missingItemCount}
                                        onChange={() => !disabled && handleOnChange(value)}
                                    />
                                </SummaryContainer>
                            )}
                        </OptionContainer>
                    );
                },
            )}
        </Container>
    );
};
