import { useAppDispatch, useAppSelector } from 'redux/store';
import {
    getRestaurantId,
    getCurrentMenuId,
    getTableId,
    getCurrentConsumptionModeType,
    getIsFoodCourt,
    getIdempotencyKey,
    updateAppState,
} from 'redux/app';
import { getAccessToken, getCustomerId, getCustomerName } from 'redux/customer/customer.slice';
import {
    getOrderCart,
    getOrderComment,
    getOrderPromocode,
    getOrders,
    getOrdersTotalInclTaxWithDiscount,
} from 'redux/orders';
import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useEffect } from 'react';

import { useCreateOrderMutation } from 'services/order/order.endpoints';
import { RequiredActionState, updateRequiredAction } from 'redux/requiredAction';
import { getAuthenticationType, getBrand, getDelayListForCreateOrder } from 'redux/brand';
import { usePostMultiOrderMutation } from 'services/multi-order/multi-order.endpoint';
import { PaymentMode } from 'pages/Payment/ChoosePayment/useChoosePaymentVM';
import { getConsumptionModeList } from 'redux/consumptionModeList';

import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { OrderErrorPayload, MultiOrderErrorPayload } from 'services/order/order.type';
import { extractErrorsCode } from 'utils/errors';
import { useUpdateOrder } from 'hooks/useUpdateOrder/useUpdateOrder';
import { errorsParser } from 'utils/errors/errorsParser';
import { useCartHandler } from 'hooks/useCartHandler';
import { computeCartPayload, rectifyCart } from 'utils/cart';
import { useMenuHandler } from 'hooks/useMenuHandler';
import { useCustomFlush } from 'hooks/useCustomFlush';
import {
    computeCreateMultiOrderPayload,
    computeCreateOrderPayload,
    isMainStatusValid,
    PaygreenMetadata,
} from './useCreateOrder.utils';

export const useCreateOrder = () => {
    const dispatch = useAppDispatch();
    const { navigateToOrderError, navigateToCartError } = useCustomNavigate();
    const { goCurrentOrder } = useWorkflowStateMachine();
    const { updateOrders } = useUpdateOrder();
    const { flushCart, flushRequiredAction, flushOrderStatuses, flushIsPaymentConfirmed } =
        useCustomFlush();

    const brand = useAppSelector(getBrand);
    const currentRestaurantId = useAppSelector(getRestaurantId);
    const currentMenuId = useAppSelector(getCurrentMenuId);
    const tableId = useAppSelector(getTableId);
    const customerId = useAppSelector(getCustomerId);
    const authenticationType = useAppSelector(getAuthenticationType);
    const shouldAuthenticate = authenticationType === 'EmailOnly';
    const accessToken = useAppSelector(getAccessToken);
    const orders = useAppSelector(getOrders);
    const orderCart = useAppSelector(getOrderCart(currentRestaurantId));
    const orderComment = useAppSelector(getOrderComment(currentRestaurantId));
    const orderPromocode = useAppSelector(getOrderPromocode(currentRestaurantId));
    const totalInclTaxWithDiscount = useAppSelector(getOrdersTotalInclTaxWithDiscount);
    const isFoodCourt = useAppSelector(getIsFoodCourt);
    const customerName = useAppSelector(getCustomerName);
    const idempotencyKey = useAppSelector(getIdempotencyKey);

    const consumptionModeList = useAppSelector(getConsumptionModeList);
    const currentConsumptionModeType = useAppSelector(getCurrentConsumptionModeType);
    const delayList = useAppSelector(getDelayListForCreateOrder(currentConsumptionModeType));

    const setRequiredAction = useCallback(
        (payload: RequiredActionState) => dispatch(updateRequiredAction(payload)),
        [dispatch],
    );

    const { refetchMenu } = useMenuHandler();
    const {
        handlePreview,
        isLoading: isPreviewLoading,
        data: rectifiedPreviewData,
        isError: isPreviewError,
    } = useCartHandler();

    const [
        createOrderQuery,
        {
            data: orderCreated,
            isLoading: singleLoading,
            error: singleError,
            isSuccess: singleSuccess,
        },
    ] = useCreateOrderMutation();

    const [
        createMultiOrderQuery,
        {
            data: multiOrderCreated,
            isLoading: multiLoading,
            error: multiError,
            isSuccess: multiSuccess,
        },
    ] = usePostMultiOrderMutation();

    const resetIdempotencyKeyAndNavigateToOrderError = useCallback(() => {
        dispatch(
            updateAppState({
                idempotencyKey: uuidv4(),
            }),
        );
        navigateToOrderError();
    }, [dispatch, navigateToOrderError]);

    const createOrder = async ({
        paymentMode,
        stripePaymentMethodId,
        paymentMethodId,
        edenredAccessToken,
        paygreenMetadata,
    }: {
        paymentMode?: PaymentMode;
        stripePaymentMethodId?: string;
        paymentMethodId?: number;
        edenredAccessToken?: string;
        paygreenMetadata?: PaygreenMetadata;
    } = {}) => {
        flushRequiredAction();
        flushIsPaymentConfirmed();
        flushOrderStatuses();

        if (!isFoodCourt && currentRestaurantId && currentMenuId && currentConsumptionModeType) {
            const createOrderPayload = computeCreateOrderPayload({
                orderCart,
                restaurantId: currentRestaurantId,
                currentMenuId,
                tableId,
                paymentMethodId,
                totalInclTaxWithDiscount,
                currentConsumptionModeType,
                stripePaymentMethodId,
                paygreenMetadata,
                orderComment,
                orderPromocode,
                delay: delayList[currentRestaurantId],
                userName: customerName,
                ...(shouldAuthenticate && customerId ? { customerId } : {}),
            });

            await createOrderQuery({
                idempotencyKey,
                edenredAccessToken,
                body: createOrderPayload,
                ...(shouldAuthenticate && accessToken ? { accessToken } : {}),
            });
        }

        if (isFoodCourt && brand && currentConsumptionModeType) {
            const createMultiOrderPayload = computeCreateMultiOrderPayload({
                orders,
                consumptionModeList,
                paymentMode,
                currentConsumptionModeType,
                brandId: brand.brandId,
                delayList,
                stripePaymentMethodId,
                tableId,
                userName: customerName,
                idempotencyKey,
                ...(shouldAuthenticate && customerId ? { customerId } : {}),
            });

            await createMultiOrderQuery({
                ...createMultiOrderPayload,
                ...(shouldAuthenticate && accessToken ? { accessToken } : {}),
            });
        }
    };

    useEffect(() => {
        if (singleSuccess && orderCreated?.orderUuid) {
            if (orderCreated.requiredAction) {
                setRequiredAction({
                    ...orderCreated.requiredAction,
                    orderUuid: orderCreated.orderUuid,
                    orderId: orderCreated.orderId,
                });
            } else if (!isMainStatusValid(orderCreated.mainStatus)) {
                /*
                    For now we redirect to the OrderError page, maybe we will
                    want in the future to automatically retry the createOrder
                */
                resetIdempotencyKeyAndNavigateToOrderError();
                return;
            } else {
                dispatch(
                    updateAppState({
                        currentFamilyFilter: null,
                    }),
                );
                flushCart();
            }

            goCurrentOrder(orderCreated.orderUuid);
        }
    }, [
        orderCreated,
        singleSuccess,
        singleError,
        flushCart,
        setRequiredAction,
        goCurrentOrder,
        dispatch,
        resetIdempotencyKeyAndNavigateToOrderError,
    ]);

    useEffect(() => {
        if (multiSuccess && multiOrderCreated?.multiOrderId) {
            const areMainStatusesNotValid =
                multiOrderCreated.multiCreatedOrders &&
                multiOrderCreated.multiCreatedOrders.filter(
                    (multiCreatedOrder) => !isMainStatusValid(multiCreatedOrder.mainStatus),
                ).length;

            if (multiOrderCreated.requiredAction) {
                setRequiredAction({
                    ...multiOrderCreated.requiredAction,
                    multiOrderId: multiOrderCreated.multiOrderId,
                    restaurantCarts: multiOrderCreated.multiCreatedOrders.map(
                        ({ orderUuid, restaurantId }) => ({ orderUuid, restaurantId }),
                    ),
                });
            } else if (
                (multiOrderCreated.mainStatus &&
                    !isMainStatusValid(multiOrderCreated.mainStatus)) ||
                areMainStatusesNotValid
            ) {
                /*
                    For now we redirect to the OrderError page, maybe we will
                    want in the future to automatically retry the createOrder
                */
                resetIdempotencyKeyAndNavigateToOrderError();
                return;
            } else {
                dispatch(
                    updateAppState({
                        currentFamilyFilter: null,
                    }),
                );
                flushCart();
            }

            goCurrentOrder(multiOrderCreated.multiOrderId);
        }
    }, [
        multiOrderCreated,
        multiSuccess,
        multiError,
        flushCart,
        setRequiredAction,
        goCurrentOrder,
        dispatch,
        resetIdempotencyKeyAndNavigateToOrderError,
    ]);

    useEffect(() => {
        if (!currentRestaurantId || !currentMenuId || !orders) return;

        if (isPreviewLoading || rectifiedPreviewData) return;

        if (multiError || singleError) {
            const error =
                (singleError as OrderErrorPayload) ?? (multiError as MultiOrderErrorPayload);
            const rootErrorCode = error.data.code;
            const errorCodes = extractErrorsCode(error);

            if (rootErrorCode === 'stripe_card_declined') {
                resetIdempotencyKeyAndNavigateToOrderError();
            } else if (errorCodes.includes('invalid_cart')) {
                const parsedErrors = errorsParser(error.data.extraData, orders);
                const rectifiedCart = rectifyCart(orderCart, parsedErrors);
                handlePreview([
                    {
                        updatedCart: computeCartPayload(rectifiedCart),
                        restaurantId: currentRestaurantId,
                        menuId: currentMenuId,
                    },
                ]);
                refetchMenu();
            } else if (error.status === 400) {
                navigateToOrderError();
            } else {
                Sentry.captureMessage(
                    `[orderErrorConfirmEndpointRefreshed] error : ${JSON.stringify(
                        error,
                    )}, errorCodes : [${errorCodes.join(', ')}]`,
                    'debug',
                );
                resetIdempotencyKeyAndNavigateToOrderError();
            }
        }
    }, [
        multiError,
        singleError,
        orders,
        orderCart,
        currentRestaurantId,
        currentMenuId,
        handlePreview,
        refetchMenu,
        isPreviewLoading,
        rectifiedPreviewData,
        resetIdempotencyKeyAndNavigateToOrderError,
        navigateToOrderError,
    ]);

    useEffect(() => {
        if (isPreviewError) {
            navigateToOrderError();
            return;
        }
        if (rectifiedPreviewData) {
            updateOrders(rectifiedPreviewData);
            navigateToCartError();
        }
    }, [
        isPreviewError,
        rectifiedPreviewData,
        navigateToOrderError,
        navigateToCartError,
        updateOrders,
    ]);

    return {
        createOrder,
        isLoading: singleLoading || multiLoading || isPreviewLoading,
        error: singleError || multiError,
        resetIdempotencyKeyAndNavigateToOrderError,
    };
};
