import { useTranslation } from 'react-i18next';
import { Title, WithHeader, Text, Loader } from 'components';
import { LoaderContainer } from 'components/Loader/Loader.style';
import { useAccountVM } from './useAccountVM';
import {
    AccountContainer,
    DisconnectButton,
    EwalletContent,
    EwalletReloaderButton,
    Separator,
    SizedSvyIcon,
    WaitingText,
} from './Account.style';

const Account: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const {
        customerName,
        goBack,
        handleGoToEwallet,
        logout,
        isLogoutLoading,
        shouldDisplayEwalletBalance,
        shouldDisplayEwalletReloader,
        customerBalance,
        isCustomerBalanceLoading,
        textBalance,
    } = useAccountVM();

    return (
        <>
            <WithHeader
                isSticky={true}
                headerContent={
                    <Title
                        data-testid="customer-name"
                        type={5}
                        text={'account_page.hello'}
                        values={{ customerName }}
                    />
                }
                onClickBack={goBack}
            >
                <EwalletContent>
                    {isCustomerBalanceLoading ? (
                        <LoaderContainer>
                            <Loader />
                        </LoaderContainer>
                    ) : (
                        <>
                            {shouldDisplayEwalletBalance && customerBalance !== undefined && (
                                <Text
                                    data-testid="customer-balance"
                                    text={textBalance}
                                    values={{ customerBalance }}
                                />
                            )}
                            {shouldDisplayEwalletReloader && (
                                <EwalletReloaderButton
                                    color="destructive"
                                    size="m"
                                    onClick={handleGoToEwallet}
                                    data-testid="reload-btn"
                                >
                                    {t('account_page.ewallet_reload')}
                                </EwalletReloaderButton>
                            )}
                        </>
                    )}
                </EwalletContent>

                <AccountContainer>
                    <Title text="🚧" noTranslation type={1} />
                    <WaitingText type={2} text={'account_page.waiting_page'} />
                </AccountContainer>

                <Separator />

                <DisconnectButton
                    variant="text"
                    color="destructive"
                    size="m"
                    onClick={logout}
                    disabled={isLogoutLoading}
                    elementLeft={<SizedSvyIcon icon="logout-circle-r-line" />}
                    data-testid="logout-btn"
                >
                    {t('account_page.logout')}
                </DisconnectButton>
            </WithHeader>
        </>
    );
};

export default Account;
