import { useInterpret } from '@xstate/react';
import { routes } from 'core/routes';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { ActionList } from 'hooks/useWorkflowStateMachine/types';
import { useLayoutEffect } from 'react';
import { useAppSelector } from 'redux/store';
import { useLocation } from 'react-router-dom';
import {
    getAuthenticationType,
    getBrandOptionStatus,
    getIsOrderFollowUpEnabled,
} from 'redux/brand';
import { getRouteAttributesFromPath } from 'utils/routes';
import { getRestaurantId } from 'redux/app';
import { getAccessToken } from 'redux/customer/customer.slice';
import { computeWorkflowStateMachine } from './useWorkflowStateMachine.utils';

export const useInitWorkflowStateMachine = () => {
    const { pathname } = useLocation();
    const isFoodCourt = useAppSelector(getBrandOptionStatus('FOOD_COURT_MODE'));
    const restaurantId = useAppSelector(getRestaurantId);
    const isOrderFollowUpEnabled = useAppSelector(getIsOrderFollowUpEnabled(restaurantId));
    const authenticationType = useAppSelector(getAuthenticationType);
    const accessToken = useAppSelector(getAccessToken);

    const {
        navigateToHome,
        navigateToRestaurants,
        navigateToMenuHome,
        navigateToLogin,
        navigateToCart,
        navigateToCrossSelling,
        navigateToCustomerInformation,
        navigateToChoosePayment,
        navigateToOrderConfirmation,
        navigateToOrderFollowUp,
    } = useCustomNavigate();

    const actions: ActionList = {
        navigateToHome,
        navigateToRestaurants,
        navigateToMenuHome,
        navigateToLogin,
        navigateToCart,
        navigateToCrossSelling,
        navigateToCustomerInformation,
        navigateToChoosePayment,
        navigateToOrderConfirmation,
        navigateToOrderFollowUp,
    };

    const workflowService = useInterpret(
        computeWorkflowStateMachine({
            isFoodCourt,
            isOrderFollowUpEnabled,
            shouldAuthenticate: authenticationType === 'EmailOnly',
        }),
        {
            actions,
        },
    );

    useLayoutEffect(() => {
        const matchedRoute = getRouteAttributesFromPath(pathname, routes);
        if (matchedRoute?.state) {
            workflowService.send({
                type: 'INIT',
                state: matchedRoute.state,
            });
        }
    }, [pathname, workflowService]);

    useLayoutEffect(() => {
        workflowService.send({ type: 'UPDATE_AUTH', isAuthenticated: !!accessToken });
    }, [accessToken, workflowService]);

    return workflowService;
};
