import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getIsFoodCourt } from 'redux/app';
import { getAuthenticationType } from 'redux/brand';
import { useAppSelector } from 'redux/store';
import { getAccessToken } from 'redux/customer/customer.slice';
import { useSendAnonymousMultiOrderReceiptMutation } from 'services/multi-order/multi-order.endpoint';
import { useSendAnonymousOrderReceiptMutation } from 'services/order/order.endpoints';
import { ChannelId } from 'services/order/order.type';
import { isEmailValid } from 'utils/email';
import { stringifyError } from 'utils/errors';

export const useEmailReceiptModalVM = (onHide: () => void) => {
    const { t } = useTranslation();
    const { orderUuid } = useParams();
    const isFoodCourt = useAppSelector(getIsFoodCourt);
    const authenticationType = useAppSelector(getAuthenticationType);
    const shouldAuthenticate = authenticationType === 'EmailOnly';
    const accessToken = useAppSelector(getAccessToken);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string | undefined>(undefined);
    const [isEmailTouched, setIsEmailTouched] = useState<boolean>(false);

    const [
        sendAnonymousOrderReceipt,
        {
            isLoading: singleLoading,
            error: singleError,
            isSuccess: singleSuccess,
            reset: singleReset,
        },
    ] = useSendAnonymousOrderReceiptMutation();

    const [
        sendAnonymousMultiOrderReceipt,
        { isLoading: multiLoading, error: multiError, isSuccess: multiSuccess, reset: multiReset },
    ] = useSendAnonymousMultiOrderReceiptMutation();

    const handleOnHide = () => {
        if (singleSuccess) {
            setIsEmailTouched(false);
            setEmailError(undefined);
            setEmail('');
            singleReset();
        }
        if (multiSuccess) {
            setIsEmailTouched(false);
            setEmailError(undefined);
            setEmail('');
            multiReset();
        }
        onHide();
    };

    const handleOnblur = (event: ChangeEvent<HTMLInputElement>) => {
        setIsEmailTouched(true);

        if (!isEmailValid(event.target.value)) {
            setEmailError(t('invalid_email'));
        }
    };

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);

        if (isEmailValid(event.target.value)) {
            setIsEmailTouched(true);
            setEmailError(undefined);
        } else {
            isEmailTouched && setEmailError(t('invalid_email'));
        }
    };

    const handleSendEmail = () => {
        setIsSubmitting(true);

        if (email && orderUuid && !isFoodCourt) {
            sendAnonymousOrderReceipt({
                email,
                orderUuid,
                channelId: ChannelId.WEB,
                ...(shouldAuthenticate && accessToken ? { accessToken } : {}),
            });
        }
        if (email && orderUuid && isFoodCourt) {
            sendAnonymousMultiOrderReceipt({
                email,
                multiOrderId: orderUuid,
                channelId: ChannelId.WEB,
                ...(shouldAuthenticate && accessToken ? { accessToken } : {}),
            });
        }

        setIsSubmitting(false);
    };

    const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleSendEmail();
    };

    useEffect(() => {
        if (singleError) {
            setEmailError(stringifyError(singleError));
        } else if (multiError) {
            setEmailError(stringifyError(multiError));
        } else {
            setEmailError(undefined);
        }
    }, [singleError, multiError]);

    const isLoading = singleLoading || multiLoading || isSubmitting;

    return {
        buttonText: {
            send: t('send'),
            close: t('close'),
        },
        email,
        emailError,
        isEmailTouched,
        isLoading,
        isSuccess: singleSuccess || multiSuccess,
        isDisabled: isLoading || !isEmailValid(email),
        handleOnChange,
        handleOnblur,
        handleOnHide,
        handleOnSubmit,
        handleSendEmail,
    };
};
