import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useAuthenticate } from 'hooks/useAuthenticate/useAuthenticate';
import { getAccessToken, updateCustomer } from 'redux/customer/customer.slice';
import { useMeQuery } from 'services/authentication/authentication.endpoint';
import { useCartHandler } from 'hooks/useCartHandler/useCartHandler';
import { getOrders } from 'redux/orders/orders.slice';
import { computeCartPayload } from 'utils/cart';

const CustomerAuthGate: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const accessToken = useAppSelector(getAccessToken);
    const { logout } = useAuthenticate();
    const { handlePreview } = useCartHandler();
    const orders = useAppSelector(getOrders);
    const [hasLoggedOut, setHasLoggedOut] = useState(false);
    const [hasCheckedAccessToken, setHasCheckedAccessToken] = useState(false);
    const [hasRefreshedCart, setHasRefreshedCart] = useState(false);

    const {
        data: customerData,
        isError: isCustomerError,
        isLoading: isCustomerLoading,
    } = useMeQuery({ token: accessToken ?? undefined }, { skip: !accessToken });

    useEffect(() => {
        if (isCustomerError && !hasLoggedOut) {
            setHasLoggedOut(true);
            logout();
        }
    }, [isCustomerError, logout, hasLoggedOut]);

    useEffect(() => {
        if (hasCheckedAccessToken) return;

        if (customerData && !isCustomerError && !isCustomerLoading) {
            setHasCheckedAccessToken(true);
            dispatch(
                updateCustomer({
                    customerId: customerData.customerId,
                    email: customerData.email,
                    phone: customerData.phone,
                    customerName: `${customerData.firstName} ${customerData.lastName}`,
                    accountPaymentType: customerData.student?.accountPaymentType,
                    ewalletOverdraftAmount: customerData.ewalletOverdraftAmount,
                }),
            );
        }
    }, [hasCheckedAccessToken, isCustomerError, isCustomerLoading, customerData, dispatch]);

    useEffect(() => {
        if (!hasCheckedAccessToken) return;
        if (hasRefreshedCart) return;

        if (orders) {
            const payload = Object.values(orders).map((order) => ({
                updatedCart: computeCartPayload(order.cart),
                restaurantId: order.restaurantId,
                menuId: order.menuId,
            }));

            handlePreview(payload);
        }

        setHasRefreshedCart(true);
    }, [hasCheckedAccessToken, hasRefreshedCart, orders, handlePreview]);

    return null;
};

export default CustomerAuthGate;
