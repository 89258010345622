import React from 'react';
import {
    Button,
    Input,
    Title,
    PageWithBottomBarContainer,
    HeaderTitle,
    WithHeader,
    BottomBar,
} from 'components';
import { useTranslation } from 'react-i18next';
import { CartStepper } from 'components/CartStepper';
import { useCustomerInformationVM } from './useCustomerInformationVM';
import {
    CustomerInformationContentContainer,
    CustomerNameContainer,
} from './CustomerInformation.style';

const CustomerInformation: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { buttonText, customerName, handleUpdateCustomerName, goNext, CUSTOMER_NAME_LENGTH_MIN } =
        useCustomerInformationVM();

    return (
        <WithHeader isSticky={true}>
            <CartStepper />
            <PageWithBottomBarContainer>
                <HeaderTitle>
                    <Title text={'customer_information.title'} type={5} />
                </HeaderTitle>

                <CustomerInformationContentContainer>
                    <CustomerNameContainer>
                        <Input
                            onChange={handleUpdateCustomerName}
                            autoFocus={false}
                            placeholder={'customer_information.input_placeholder'}
                            value={customerName || ''}
                            label={t('customer_information.name')}
                            labelDetail={t('customer_information.mandatory')}
                            helperText={t('customer_information.name_description')}
                            data-testid="customer-name-input"
                        />
                    </CustomerNameContainer>
                </CustomerInformationContentContainer>

                <BottomBar withPadding>
                    <Button
                        fullWidth
                        onClick={goNext}
                        data-testid="validate-btn"
                        size="l"
                        disabled={!customerName || customerName.length < CUSTOMER_NAME_LENGTH_MIN}
                    >
                        {buttonText}
                    </Button>
                </BottomBar>
            </PageWithBottomBarContainer>
        </WithHeader>
    );
};

export default CustomerInformation;
