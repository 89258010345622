import React from 'react';
import {
    Button,
    Loader,
    PriceBreakDownSummary,
    Text,
    WithHeader,
    BottomBar,
    OrdersAccordion,
    PageWithBottomBarContainer,
    Callout,
    Title,
} from 'components';
import { SvyIcon } from '@innovorder/savory';
import { CartStepper } from 'components/CartStepper';
import { MargedContainer } from '../OrderConfirmation/OrderConfirmation.style';
import StripeRequireAction from '../StripeRequireAction';
import EmailReceiptModal from '../EmailReceiptModal';
import {
    BottomBarButtonsContainer,
    LoaderContainer,
    MarginedButton,
    OrderFollowUpContentContainer,
    PriceBreakDownSummaryContainer,
    SpacedHeaderTitle,
    StyledColoredTitle,
    StyledIcon,
    StyledInfoCallout,
    TitleContainer,
} from './OrderFollowUp.style';
import { useOrderFollowUpVM } from './useOrderFollowUpVM';
import { useOrderVM } from '../useOrderVM';
import OrderNotificationModal from '../OrderNotificationModal';
import OrderStatusModal from '../OrderStatusModal';
import OrderFollowUpExplanations from './Explanations/Explanations';

const OrderFollowUp: React.FunctionComponent = () => {
    const {
        isPaymentConfirmed,
        hasStripeRequiredAction,
        showEmailReceiptModal,
        handleShowEmailReceiptModal,
        handleHideEmailReceiptModal,
        isAuthenticated,
    } = useOrderVM();
    const {
        buttonText,
        multiOrderCreatedAt,
        isDisconnected,
        restaurantOrders,
        accordionItems,
        foodCourtPictureMap,
        openNewTabToLocateKiosks,
        totalPriceBreakdown,
        currency,
        discounts,
        entranceFees,
        grants,
        orderStatusModalPayload,
        handleHideOrderStatusModal,
        disableHeaderClick,
        redirectToHome,
        isExplanationsPageOpen,
        closeExplanationsPage,
        customerName,
        isLoading,
    } = useOrderFollowUpVM();

    if (hasStripeRequiredAction && !isPaymentConfirmed) {
        return <StripeRequireAction />;
    }

    if (hasStripeRequiredAction && isPaymentConfirmed) {
        return (
            <LoaderContainer>
                <Loader size={50} />
                <Text text={'order_loading'} type={2} />
            </LoaderContainer>
        );
    }

    if (isExplanationsPageOpen) {
        return <OrderFollowUpExplanations onClick={closeExplanationsPage} />;
    }

    if (isLoading) {
        return (
            <LoaderContainer>
                <Loader size={50} />
            </LoaderContainer>
        );
    }

    return (
        <WithHeader
            isSticky={true}
            onClickBack={redirectToHome}
            onClickLogo={redirectToHome}
            disabledClickBack={disableHeaderClick}
            disabledClickLogo={disableHeaderClick}
            hasAccountButton
        >
            <CartStepper />
            <PageWithBottomBarContainer>
                <SpacedHeaderTitle>
                    <TitleContainer>
                        <StyledColoredTitle text={customerName} type={5} noTranslation />
                        <Title text={'order_follow_up.multi_order_date'} type={5} />
                        <StyledColoredTitle text={multiOrderCreatedAt} type={5} noTranslation />
                    </TitleContainer>

                    <StyledInfoCallout variant="info">
                        <StyledIcon icon="information-line" data-testid="information-line" />
                        <Text text={'order_follow_up.description'} type={3} />
                    </StyledInfoCallout>
                </SpacedHeaderTitle>

                <OrderFollowUpContentContainer>
                    <MargedContainer data-testid="products">
                        {accordionItems && <OrdersAccordion items={accordionItems} />}
                    </MargedContainer>
                </OrderFollowUpContentContainer>

                <PriceBreakDownSummaryContainer>
                    {currency && totalPriceBreakdown && (
                        <PriceBreakDownSummary
                            currency={currency}
                            discounts={discounts}
                            entranceFees={entranceFees}
                            grants={grants}
                            {...totalPriceBreakdown}
                        />
                    )}
                </PriceBreakDownSummaryContainer>

                <BottomBar>
                    {isDisconnected && (
                        <Callout>
                            <StyledIcon icon="wifi-off-line" data-testid="wifi-off-line" />
                            <Text text={'disconnected_warning'} type={3} weight="Bold" />
                        </Callout>
                    )}

                    <BottomBarButtonsContainer
                        withPadding={!!foodCourtPictureMap || !isAuthenticated}
                    >
                        {foodCourtPictureMap && (
                            <MarginedButton
                                fullWidth
                                onClick={openNewTabToLocateKiosks}
                                elementLeft={<SvyIcon icon="map-pin-line" />}
                            >
                                {buttonText.locateKiosk}
                            </MarginedButton>
                        )}

                        {!isAuthenticated && (
                            <Button
                                fullWidth
                                variant="outline"
                                onClick={handleShowEmailReceiptModal}
                                elementLeft={<SvyIcon icon="download-2-line" />}
                            >
                                {buttonText.receipt}
                            </Button>
                        )}
                    </BottomBarButtonsContainer>
                </BottomBar>

                <EmailReceiptModal
                    isOpen={showEmailReceiptModal}
                    onHide={handleHideEmailReceiptModal}
                />
                <OrderStatusModal
                    payload={orderStatusModalPayload}
                    onHide={handleHideOrderStatusModal}
                />
                <OrderNotificationModal restaurantOrders={restaurantOrders} />
            </PageWithBottomBarContainer>
        </WithHeader>
    );
};

export default OrderFollowUp;
