import React from 'react';
import { Button, Input, Title, BottomBar } from 'components';
import { usePaygreenVM } from './usePaygreenVM';
import BaseConfirmPayment from '../BaseConfirmPayment';
import {
    Container,
    CardContent,
    TitleContainer,
    CustomerInformationContentContainer,
    CustomerNameContainer,
    PaygreenConfirmTitle,
} from './Paygreen.style';

const Paygreen: React.FunctionComponent = () => {
    const {
        buttonText,
        handleSubmit,
        firstName,
        lastName,
        email,
        loading,
        handleUpdateFirstName,
        handleUpdateLastName,
        handleUpdateEmail,
        error,
    } = usePaygreenVM();

    return (
        <BaseConfirmPayment isLoading={loading}>
            <Container onSubmit={handleSubmit}>
                <CardContent>
                    <PaygreenConfirmTitle text={'additional_customer_information'} type={4} />

                    <CustomerInformationContentContainer>
                        <CustomerNameContainer>
                            <TitleContainer>
                                <Title text={'customer_information.name'} type={5} />
                            </TitleContainer>
                            <Input
                                onChange={handleUpdateFirstName}
                                autoFocus={false}
                                value={firstName || ''}
                            />
                        </CustomerNameContainer>

                        <CustomerNameContainer>
                            <TitleContainer>
                                <Title text={'customer_information.last_name'} type={5} />
                            </TitleContainer>
                            <Input
                                onChange={handleUpdateLastName}
                                autoFocus={false}
                                value={lastName || ''}
                            />
                        </CustomerNameContainer>

                        <CustomerNameContainer>
                            <TitleContainer>
                                <Title text={'customer_information.email'} type={5} />
                            </TitleContainer>
                            <Input
                                onChange={handleUpdateEmail}
                                autoFocus={false}
                                value={email || ''}
                            />
                        </CustomerNameContainer>
                    </CustomerInformationContentContainer>
                </CardContent>
                <BottomBar withPadding>
                    <Button fullWidth disabled={error} isLoading={loading} type="submit" size="l">
                        {buttonText}
                    </Button>
                </BottomBar>
            </Container>
        </BaseConfirmPayment>
    );
};

export default Paygreen;
