import { computePrice } from 'utils/price';
import i18n from 'core/InitialiseThirdParties/i18n';
import { AccountPaymentType } from 'services/customer/customer.type';
import { computeCustomerBalanceWithOverdraft } from 'utils/customer';

export const computeCustomerBalance = (
    customerBalance: number,
    ewalletOverdraftAmount: number | null,
    currency: string | undefined,
    accountPaymentType: AccountPaymentType | null,
) => {
    if (!currency) {
        return undefined;
    }

    if (accountPaymentType === 'POSTPAYMENT') {
        const customerBalanceWithOverdraft = computeCustomerBalanceWithOverdraft(
            customerBalance,
            ewalletOverdraftAmount,
        );

        return computePrice(i18n, customerBalanceWithOverdraft, currency);
    }

    return computePrice(i18n, customerBalance, currency);
};
