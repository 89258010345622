export const computeCustomerBalanceWithOverdraft = (
    customerBalance: number,
    ewalletOverdraftAmount: number | null,
) => {
    if (ewalletOverdraftAmount) {
        return customerBalance + ewalletOverdraftAmount;
    }

    return customerBalance;
};
