import { useAppSelector } from 'redux/store';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getOrderRecap, getOrders, getOrdersTotalPriceBreakdown } from 'redux/orders';
import { getBrandCurrency } from 'redux/brand';
import { computePrice } from 'utils/price';
import { useCreateOrder } from 'hooks/useCreateOrder';
import { usePaygreen } from 'hooks/usePaygreen/usePaygreen';
import { SelectOptions } from 'components/RadioSelect';
import { useHasToPay } from 'hooks/useHasToPay/useHasToPay';
import { useEdenredPayment } from 'hooks/useEdenredPayment/useEdenredPayment';

import { getRestaurantId } from 'redux/app';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { usePostPayment } from 'hooks/usePostPayment/usePostPayment';
import { useStripePayment } from 'hooks/useStripePayment/useStripePayment';
import { useConfirmPayment } from 'hooks/useConfirmPayment/useConfirmPayment';
import { useGetConsumptionModes } from 'hooks/useGetConsumptionModes';
import { parseRestaurantId } from 'pages/Home/Home.utils';
import { isNumber } from 'utils/typescript';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { useEwalletPayment } from 'hooks/useEwalletPayment';
import { computeInitialPaymentMethod, generatePaymentMethodOptions } from './ChoosePayment.utils';

export type PaymentStep = 'choose_payment_method' | 'stripe_order_confirmation';
export type PaymentMode =
    | 'stripe'
    | 'ewallet'
    | 'google_pay'
    | 'apple_pay'
    | 'edenred'
    | 'paygreen'
    | 'luncheon_vouchers'
    | 'cash'
    | 'creditcard'
    | 'payment_later';

export const useChoosePaymentVM = () => {
    const {
        navigateBackToChoosePayment,
        navigateToCardPayment,
        navigateToGooglePayment,
        navigateToApplePayment,
        navigateToEdenredPayment,
        navigateToPaygreenPayment,
        navigateToLuncheonVoucherPayment,
        navigateToPaymentLater,
    } = useCustomNavigate();

    const { t, i18n } = useTranslation();
    const { state } = useLocation() as {
        state?: {
            googlePayError?: boolean;
            applePayError?: boolean;
            threeDSError?: boolean;
        };
    };
    const { hasToPay } = useHasToPay();

    const currency = useAppSelector(getBrandCurrency);
    const restaurantId = useAppSelector(getRestaurantId);
    const recap = useAppSelector(getOrderRecap(restaurantId));
    const totalPriceBreakdown = useAppSelector(getOrdersTotalPriceBreakdown);

    const orders = useAppSelector(getOrders);
    const restaurantIds = useMemo(
        () =>
            orders
                ? Object.keys(orders)
                      .map((id) => parseRestaurantId(id))
                      .filter(isNumber)
                : [],
        [orders],
    );
    const { isFetching: isConsumptionModesFetching, isFetched: isConsumptionModesFetched } =
        useGetConsumptionModes(restaurantIds);

    const { canPayWithCash, canPayWithCreditCard } = usePostPayment();
    const { hasStripe } = useStripePayment();
    const ewalletEligibilityConditions = useEwalletPayment();

    const {
        hasEdenredPaymentMethod,
        cartContainsOnlyEligibleProducts: isCartCompatibleWithEdenred,
        amountIsBelowMaximumDaily,
    } = useEdenredPayment();

    const {
        hasPaygreenPaymentMethod,
        cartContainsOnlyEligibleProducts: isCartCompatibleWithPaygreen,
    } = usePaygreen();

    const googlePayError = state?.googlePayError;
    const applePayError = state?.applePayError;
    const threeDSError = state?.threeDSError;

    const [isThreeDSErrorModalOpen, setIsThreeDSErrorModalOpen] = useState(!!threeDSError);
    const handleHideThreeDSErrorModal = () => {
        setIsThreeDSErrorModalOpen(false);
    };

    const { goPrevious } = useWorkflowStateMachine();

    const handleHideNoPaymentMethodModal = () => {
        goPrevious();
    };

    const {
        paymentOptions: paymentMethodOptions,
        luncheonOptions: paymentMethodLuncheonOptions,
        payLaterOptions: paymentMethodLaterOptions,
    }: {
        paymentOptions: SelectOptions<PaymentMode>[];
        luncheonOptions: SelectOptions<PaymentMode>[];
        payLaterOptions: SelectOptions<PaymentMode>[];
    } = generatePaymentMethodOptions({
        hasStripe,
        totalInclTaxWithDiscount: totalPriceBreakdown.totalInclTaxWithDiscount,
        currency,
        googlePayError,
        applePayError,
        hasEdenredPaymentMethod,
        edenredEligibilityConditions: {
            cartContainsOnlyEligibleProducts: isCartCompatibleWithEdenred,
            amountIsBelowMaximumDaily,
        },
        useCashPayment: canPayWithCash,
        useCreditCardPayment: canPayWithCreditCard,
        hasPaygreenPaymentMethod,
        paygreenEligibilityConditions: {
            cartContainsOnlyEligibleProducts: isCartCompatibleWithPaygreen,
        },
        ewalletEligibilityConditions,
    });

    const [paymentMode, setPaymentMethod] = useState<PaymentMode | undefined>(undefined);

    const [isPaymentModeInitialized, setIsPaymentModeInitialized] = useState(false);

    useEffect(() => {
        if (!isConsumptionModesFetching && isConsumptionModesFetched && !isPaymentModeInitialized) {
            setPaymentMethod(
                computeInitialPaymentMethod({
                    paymentMethodOptions,
                    googlePayError: !!googlePayError,
                    applePayError: !!applePayError,
                }),
            );
            setIsPaymentModeInitialized(true);
        }
    }, [
        isConsumptionModesFetching,
        isConsumptionModesFetched,
        isPaymentModeInitialized,
        paymentMethodOptions,
        paymentMethodLuncheonOptions,
        paymentMethodLaterOptions,
        googlePayError,
        applePayError,
    ]);

    const { createOrder, isLoading: isLoadingCreateOrder } = useCreateOrder();

    const handlePaymentModeChange = (mode: PaymentMode | undefined) => {
        mode && setPaymentMethod(mode);

        if (mode === 'luncheon_vouchers') {
            navigateToLuncheonVoucherPayment();
        }

        if (mode === 'payment_later') {
            navigateToPaymentLater();
        }
    };

    const navigateToNextPaymentStep = useCallback(() => {
        switch (paymentMode) {
            case 'google_pay':
                navigateToGooglePayment();
                break;
            case 'apple_pay':
                navigateToApplePayment();
                break;
            case 'edenred':
                navigateToEdenredPayment();
                break;
            case 'paygreen':
                navigateToPaygreenPayment();
                break;
            default:
                navigateToCardPayment();
        }
    }, [
        navigateToApplePayment,
        navigateToCardPayment,
        navigateToEdenredPayment,
        navigateToPaygreenPayment,
        navigateToGooglePayment,
        paymentMode,
    ]);

    const isLuncheonPaymentMethodSelected = !!paymentMethodLuncheonOptions.find(
        (option) => option.value === paymentMode,
    );

    const isPaymentMethodLaterOptions = !!paymentMethodLaterOptions.find(
        (option) => option.value === paymentMode,
    );

    const amountWithCurrency =
        currency && totalPriceBreakdown
            ? computePrice(i18n, totalPriceBreakdown.totalInclTaxWithDiscount, currency)
            : '';
    const buttonText = {
        pay: t('button_pay', {
            amount: amountWithCurrency,
        }),
        confirm: t('payment.confirm'),
    };

    const { handlePaymentSubmit, isLoadingCreateOrder: isLoadingConfirmOrder } =
        useConfirmPayment(paymentMode);

    const handleSubmit = useCallback(() => {
        if (isPaymentMethodLaterOptions || paymentMode === 'ewallet') {
            /**
             * confirm order directly if payment is later or ewallet
             */
            handlePaymentSubmit();
        } else if (hasToPay) {
            /**
             * go to next step if amount > 0
             */
            navigateToNextPaymentStep();
        } else {
            /**
             * create order directly if amount is 0
             */
            createOrder();
        }
    }, [
        hasToPay,
        paymentMode,
        isPaymentMethodLaterOptions,
        navigateToNextPaymentStep,
        createOrder,
        handlePaymentSubmit,
    ]);

    const isLoading = isLoadingCreateOrder || isLoadingConfirmOrder;

    return {
        currency,
        totalPriceBreakdown,
        recap,
        hasToPay,
        buttonText,
        buttonIsDisabled: isLoading || (!paymentMode && hasToPay),
        paymentMethodOptions,
        paymentMethodLuncheonOptions,
        isLuncheonPaymentMethodSelected,
        paymentMethodLaterOptions,
        isPaymentMethodLaterOptions,
        handlePaymentModeChange,
        paymentMode,
        isLoading,
        isConsumptionModesFetching,
        navigateBackToChoosePayment,
        handleSubmit,
        threeDSError,
        isThreeDSErrorModalOpen,
        handleHideThreeDSErrorModal,
        isPaymentOptionsUnavailable:
            paymentMethodOptions.length === 0 &&
            !isConsumptionModesFetching &&
            isConsumptionModesFetched,
        handleHideNoPaymentMethodModal,
    };
};
