import { StepperWorkflowStates } from './useCartStepper.types';

export const computeSvyStepperSteps = (
    isFoodCourt: boolean | null,
    isOrderFollowUpEnabled: boolean | undefined,
    shouldAuthenticate: boolean,
    isAuthenticated: boolean,
): StepperWorkflowStates => {
    const steps = [
        { stateValue: 'cart', translation: 'cart.stepper.cart' },
        { stateValue: 'customerInformation', translation: 'cart.stepper.name' },
        { stateValue: 'payment', translation: 'cart.stepper.payment' },
        {
            stateValue:
                isFoodCourt || isOrderFollowUpEnabled ? 'orderFollowUp' : 'orderConfirmation',
            translation:
                isFoodCourt || isOrderFollowUpEnabled
                    ? 'cart.stepper.preparation'
                    : 'cart.stepper.summary',
        },
    ];

    const filteredSteps =
        shouldAuthenticate && isAuthenticated
            ? steps.filter((step) => step.stateValue !== 'customerInformation')
            : steps;

    return filteredSteps.map((step, index) => ({
        index,
        ...step,
    })) as StepperWorkflowStates;
};
