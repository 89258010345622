import { SVGIcon, SVGIcons } from 'components/SvgIcon/SVGIcon';

import { SelectOptions } from 'components/RadioSelect';
import { TranslationKey } from 'locales/fr';
import i18n from 'core/InitialiseThirdParties/i18n';
import { computePrice } from 'utils/price';
import { SvyBadge } from '@innovorder/savory';
import { PaymentMode } from './useChoosePaymentVM';

export const computeInitialPaymentMethod = ({
    paymentMethodOptions,
    googlePayError,
    applePayError,
}: {
    paymentMethodOptions: SelectOptions<PaymentMode>[];
    googlePayError: boolean;
    applePayError: boolean;
}): PaymentMode | undefined => {
    if (!paymentMethodOptions.length) {
        return undefined;
    }

    const defaultPaymentMethod = paymentMethodOptions[0];

    if (defaultPaymentMethod.value === 'google_pay' && !googlePayError) {
        return 'google_pay';
    }

    if (defaultPaymentMethod.value === 'apple_pay' && !applePayError) {
        return 'apple_pay';
    }

    if (paymentMethodOptions.find(({ value }) => value === 'stripe')) {
        return 'stripe';
    }

    return undefined;
};

export const generatePaymentMethodOptions = ({
    totalInclTaxWithDiscount,
    currency,
    hasStripe,
    googlePayError,
    applePayError,
    hasEdenredPaymentMethod,
    edenredEligibilityConditions,
    useCashPayment,
    useCreditCardPayment,
    hasPaygreenPaymentMethod,
    paygreenEligibilityConditions,
    ewalletEligibilityConditions,
}: {
    hasStripe: boolean;
    totalInclTaxWithDiscount?: number;
    currency?: string;
    googlePayError?: boolean;
    applePayError?: boolean;
    hasEdenredPaymentMethod?: boolean;
    edenredEligibilityConditions?: {
        cartContainsOnlyEligibleProducts?: boolean;
        amountIsBelowMaximumDaily?: boolean;
    };
    usePaygreenPayments?: boolean;
    useCashPayment?: boolean;
    useCreditCardPayment?: boolean;
    hasPaygreenPaymentMethod?: boolean;
    paygreenEligibilityConditions?: {
        cartContainsOnlyEligibleProducts?: boolean;
    };
    ewalletEligibilityConditions?: {
        hasToPayWithEwallet?: boolean;
        canPayWithEwallet?: boolean;
        customerBalance?: number;
        customerBalanceWithOverdraft?: number;
        shouldDisplayCustomerBalanceWithOverdraft?: boolean;
    };
}) => {
    const paymentOptions: SelectOptions<PaymentMode>[] = [];
    const luncheonOptions: SelectOptions<PaymentMode>[] = [];
    const payLaterOptions: SelectOptions<PaymentMode>[] = [];

    if (ewalletEligibilityConditions?.hasToPayWithEwallet) {
        if (ewalletEligibilityConditions?.canPayWithEwallet) {
            const ewalletPaymentOption = computeEwalletPaymentOption({
                totalInclTaxWithDiscount,
                currency,
                ...ewalletEligibilityConditions,
            });

            paymentOptions.push(ewalletPaymentOption);
        }

        return { paymentOptions, luncheonOptions, payLaterOptions };
    }

    if (hasStripe) {
        if (navigator.userAgent.match('Android')) {
            paymentOptions.push({
                name: googlePayError ? 'order_error_stripe' : 'payment.google_pay',
                icon: () => <SVGIcon icon={SVGIcons.CARRET} />,
                value: 'google_pay',
                disabled: !!googlePayError,
            });
        }

        if (navigator.userAgent.match('iPhone')) {
            paymentOptions.push({
                name: applePayError ? 'order_error_stripe' : 'payment.apple_pay',
                icon: () => <SVGIcon icon={SVGIcons.APPLEPAY} />,
                value: 'apple_pay',
                disabled: !!applePayError,
            });
        }

        paymentOptions.push({
            name: 'payment.bank_card',
            icon: () => <SVGIcon icon={SVGIcons.BANKCARD} />,
            value: 'stripe',
        });
    }

    if (ewalletEligibilityConditions?.canPayWithEwallet) {
        const ewalletPaymentOption = computeEwalletPaymentOption({
            totalInclTaxWithDiscount,
            currency,
            ...ewalletEligibilityConditions,
        });

        paymentOptions.push(ewalletPaymentOption);
    }

    if (hasEdenredPaymentMethod && edenredEligibilityConditions) {
        let error: TranslationKey | undefined;

        if (!edenredEligibilityConditions.amountIsBelowMaximumDaily) {
            error = 'payment.luncheon_voucher_errors.maximum_daily_amount';
        } else if (!edenredEligibilityConditions.cartContainsOnlyEligibleProducts) {
            error = 'payment.luncheon_voucher_errors.cart_with_non_eligible_products';
        }

        luncheonOptions.push({
            name: 'payment.edenred',
            hasLineBreakBeforeDescription: true,
            icon: () => <SVGIcon icon={SVGIcons.EDENRED} />,
            value: 'edenred',
            disabled: !!error,
            error,
        });
    }

    if (hasPaygreenPaymentMethod && paygreenEligibilityConditions) {
        let error: TranslationKey | undefined;

        if (!paygreenEligibilityConditions.cartContainsOnlyEligibleProducts) {
            error = 'payment.luncheon_voucher_errors.cart_with_non_eligible_products';
        }

        luncheonOptions.push({
            name: 'payment.paygreen.label',
            description: 'payment.paygreen.description',
            hasLineBreakBeforeDescription: true,
            icon: () => <SVGIcon icon={SVGIcons.PAYGREEN} />,
            value: 'paygreen',
            disabled: !!error,
            error,
        });
    }

    // Allows to display a "link" to luncheon vouchers list if at least one is usable
    if (luncheonOptions.length >= 1) {
        paymentOptions.push({
            name: 'payment.choice.luncheon_voucher',
            isStep: true,
            value: 'luncheon_vouchers',
        });
    }

    if (useCashPayment) {
        payLaterOptions.push({
            name: 'payment.cash',
            icon: () => <SVGIcon icon={SVGIcons.CASHLINE} />,
            value: 'cash',
        });
    }

    if (useCreditCardPayment) {
        payLaterOptions.push({
            name: 'payment.bank_card',
            icon: () => <SVGIcon icon={SVGIcons.BANKCARD} />,
            value: 'creditcard',
        });
    }

    // Allows to display a "link" to payment later list if at least one is usable
    if (payLaterOptions.length >= 1) {
        paymentOptions.push({
            name: 'payment.payment_later',
            isStep: true,
            value: 'payment_later',
        });
    }

    return { paymentOptions, luncheonOptions, payLaterOptions };
};

export const computeEwalletPaymentOption = ({
    totalInclTaxWithDiscount,
    currency,
    customerBalance,
    customerBalanceWithOverdraft,
    shouldDisplayCustomerBalanceWithOverdraft,
}: {
    totalInclTaxWithDiscount?: number;
    currency?: string;
    customerBalance?: number;
    customerBalanceWithOverdraft?: number;
    shouldDisplayCustomerBalanceWithOverdraft?: boolean;
}): SelectOptions<PaymentMode> => {
    const isAmountBelowCustomerBalanceWithOverdraft =
        customerBalanceWithOverdraft !== undefined && totalInclTaxWithDiscount
            ? totalInclTaxWithDiscount <= customerBalanceWithOverdraft
            : false;

    const wording = shouldDisplayCustomerBalanceWithOverdraft
        ? 'payment.balance_authorized_description'
        : 'payment.balance_description';

    const balance = shouldDisplayCustomerBalanceWithOverdraft
        ? customerBalanceWithOverdraft
        : customerBalance;

    const description =
        balance !== undefined && currency
            ? i18n.t(wording, {
                  customerBalance: computePrice(i18n, balance, currency),
              })
            : undefined;

    const badgeColor = computeBadgeColor({
        isAmountBelowCustomerBalanceWithOverdraft,
        balance,
    });

    const BalanceBadge = description ? (
        <SvyBadge color={badgeColor} label={description} />
    ) : undefined;

    return {
        name: i18n.t('payment.ewallet'),
        descriptionContent: BalanceBadge,
        icon: () => <SVGIcon icon={SVGIcons.EWALLET} />,
        value: 'ewallet',
        noTranslation: true,
        disabled: !isAmountBelowCustomerBalanceWithOverdraft,
    };
};

export const computeBadgeColor = ({
    isAmountBelowCustomerBalanceWithOverdraft,
    balance,
}: {
    isAmountBelowCustomerBalanceWithOverdraft: boolean;
    balance?: number;
}) => {
    if (!isAmountBelowCustomerBalanceWithOverdraft) {
        return 'neutral';
    }

    if (balance && balance > 0) {
        return 'primary';
    }

    return 'destructive';
};
