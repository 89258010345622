import {
    getAccessToken,
    getAccountPaymentType,
    getCustomerId,
    getEwalletOverdraftAmount,
} from 'redux/customer/customer.slice';

import { PaymentMethodCode } from 'services/consumptionMode/consumptionMode.type';
import { getConsumptionModeList } from 'redux/consumptionModeList';
import { getIsFoodCourt } from 'redux/app';
import { getOrders } from 'redux/orders';
import { useAppSelector } from 'redux/store';
import { useGetCustomerBalanceQuery } from 'services/customer/customer.endpoints';
import { computeCustomerBalanceWithOverdraft } from 'utils/customer';

export const useEwalletPayment = () => {
    const consumptionModeList = useAppSelector(getConsumptionModeList);
    const currentOrders = useAppSelector(getOrders);
    const isFoodCourt = useAppSelector(getIsFoodCourt);
    const accessToken = useAppSelector(getAccessToken);
    const customerId = useAppSelector(getCustomerId);
    const accountPaymentType = useAppSelector(getAccountPaymentType);
    const ewalletOverdraftAmount = useAppSelector(getEwalletOverdraftAmount);

    const { data: customerBalanceData } = useGetCustomerBalanceQuery(
        {
            customerId: customerId || 0,
            token: accessToken || '',
        },
        { skip: !accessToken || !customerId },
    );

    if (!currentOrders || !consumptionModeList) {
        return {
            hasToPayWithEwallet: false,
            canPayWithEwallet: false,
            customerBalance: undefined,
            customerBalanceWithOverdraft: undefined,
            balancerWording: undefined,
        };
    }

    if (isFoodCourt || !accessToken) {
        return {
            hasToPayWithEwallet: false,
            canPayWithEwallet: false,
            customerBalance: undefined,
            customerBalanceWithOverdraft: undefined,
            balancerWording: undefined,
        };
    }

    const canPayWithEwallet = Object.values(currentOrders).reduce<boolean>(
        (result, currentOrder) => {
            const currentConsumptionMode = currentOrder.consumptionMode;
            const orderConsumptionMode = consumptionModeList[currentOrder.restaurantId];

            if (!orderConsumptionMode) {
                return false;
            }

            const selectedConsumptionMode = orderConsumptionMode.find(
                ({ type }) => type === currentConsumptionMode,
            );

            const hasEwalletActive = !!selectedConsumptionMode?.paymentMethods.find(
                ({ code, active }) => active && code === PaymentMethodCode.EWallet,
            );

            return result && hasEwalletActive && accountPaymentType !== 'CASH';
        },
        true,
    );

    return {
        /**
         * same than shouldDisplayCustomerBalanceWithOverdraft for now but could be different in the future
         * so we keep it separate with a well defined name
         */
        hasToPayWithEwallet: accountPaymentType === 'POSTPAYMENT',
        canPayWithEwallet,
        customerBalance: customerBalanceData?.customerBalance ?? 0,
        customerBalanceWithOverdraft: computeCustomerBalanceWithOverdraft(
            customerBalanceData?.customerBalance ?? 0,
            ewalletOverdraftAmount,
        ),
        /**
         * same than hasToPayWithEwallet for now but could be different in the future
         * so we keep it separate with a well defined name
         */
        shouldDisplayCustomerBalanceWithOverdraft: accountPaymentType === 'POSTPAYMENT',
    };
};
