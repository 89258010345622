import styled from '@emotion/styled';
import { SvyBadge } from '@innovorder/savory';
import { Text } from 'components';
import { backButtonSize } from 'components/BackButton/BackButton.style';

export const ProductStepsContainer = styled.div`
    flex: 1;
    padding: ${({ theme }) => theme.base.spacing[2]};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }): string => theme.base.spacing[3]};
`;

export const Step = styled.div`
    /**
    * scroll-margin: to scroll above step so that customer can see the PortalHeader + the step name.
    * The value is the addition of :
    * - PortalHeader top
    * - PortalHeader height
    * - a small adjustment to hide the previous step
    */
    scroll-margin: ${({ theme }) => `calc(${theme.base.spacing[2]} + ${backButtonSize} + 14px)`};
`;

export const TextWithOpacity = styled(Text)`
    opacity: 0.6;
`;

export const StepHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: ${({ theme }) => theme.base.spacing[3]} 0;
`;

export const Separator = styled.div`
    height: ${({ theme }) => theme.base.spacing[1]};
    background-color: ${({ theme }): string => theme.base.colors.neutral[50]};
    border-radius: ${({ theme }): string => theme.base.radius.default};
`;

export const StyledBadge = styled(SvyBadge)`
    /**
    * scroll-margin: to scroll above step so that customer can see the PortalHeader + the step name.
    * The value is the addition of :
    * - PortalHeader top
    * - PortalHeader height
    * - Separator height
    * - StepHeader padding top
    * - a small adjustment to hide the previous step
    */
    scroll-margin: ${({ theme }) =>
        `calc(${theme.base.spacing[2]} + ${backButtonSize} + ${theme.base.spacing[3]} + ${theme.base.spacing[1]} + 14px)`};
`;
