import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { WorkflowStateStep } from 'hooks/useWorkflowStateMachine/types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getIsFoodCourt, getRestaurantId } from 'redux/app';
import { getAuthenticationType, getIsOrderFollowUpEnabled } from 'redux/brand';
import { getStatuses } from 'redux/ordersStatus/ordersStatus.slice';
import { getAccessToken } from 'redux/customer/customer.slice';
import { useAppSelector } from 'redux/store';
import { computeSvyStepperSteps } from './useCartStepper.utils';
import { StepperWorkflowStates } from './useCartStepper.types';

export const useCartStepper = () => {
    const isFoodCourt = useAppSelector(getIsFoodCourt);
    const restaurantId = useAppSelector(getRestaurantId);
    const isOrderFollowUpEnabled = useAppSelector(getIsOrderFollowUpEnabled(restaurantId));
    const shouldAuthenticate = useAppSelector(getAuthenticationType);
    const isAuthenticated = useAppSelector(getAccessToken);
    const ordersStatus = useAppSelector(getStatuses);

    const { state } = useWorkflowStateMachine();
    const { t } = useTranslation();

    const stepperWorkflowStates: StepperWorkflowStates = computeSvyStepperSteps(
        isFoodCourt,
        isOrderFollowUpEnabled,
        shouldAuthenticate === 'EmailOnly',
        !!isAuthenticated,
    );

    const indexOfState = stepperWorkflowStates
        .map((stepperWorkflowState) => stepperWorkflowState.stateValue)
        .indexOf(state.value as WorkflowStateStep);

    const getIsCompleted = useCallback(
        (workflowIndex: number) => {
            if (!isFoodCourt) {
                return workflowIndex === indexOfState;
            }

            if (workflowIndex !== indexOfState) {
                return false;
            }

            return (
                !ordersStatus ||
                Object.values(ordersStatus).every(
                    ({ status }) => status === 'COLLECTED' || status === 'CANCELLED',
                )
            );
        },
        [isFoodCourt, ordersStatus, indexOfState],
    );

    const steps =
        indexOfState !== -1
            ? [
                  ...Object.entries(stepperWorkflowStates).map(
                      ([_index, workflowState], index) => ({
                          label: t(workflowState.translation),
                          active: indexOfState >= workflowState.index,
                          completed:
                              index + 1 === stepperWorkflowStates.length
                                  ? getIsCompleted(workflowState.index)
                                  : indexOfState > workflowState.index,
                      }),
                  ),
              ]
            : undefined;

    return {
        steps,
    };
};
