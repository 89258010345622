import { useAuthenticate } from 'hooks/useAuthenticate/useAuthenticate';
import { useNavigate } from 'react-router-dom';
import { getBrandCurrency, getBrandId } from 'redux/brand/brand.slice';
import { useAppSelector } from 'redux/store';
import {
    getAccessToken,
    getAccountPaymentType,
    getCustomerId,
    getEwalletOverdraftAmount,
    getCustomerName,
} from 'redux/customer/customer.slice';
import { useGetCustomerBalanceQuery } from 'services/customer/customer.endpoints';
import { ENV } from 'utils/env';
import { TranslationKey } from 'locales/fr';
import { computeCustomerBalance } from './Account.utils';

export const useAccountVM = () => {
    const customerName = useAppSelector(getCustomerName);
    const accessToken = useAppSelector(getAccessToken);
    const customerId = useAppSelector(getCustomerId);
    const accountPaymentType = useAppSelector(getAccountPaymentType);
    const currency = useAppSelector(getBrandCurrency);
    const brandId = useAppSelector(getBrandId);
    const ewalletOverdraftAmount = useAppSelector(getEwalletOverdraftAmount);

    const shouldDisplayEwalletBalance = accountPaymentType !== 'CASH';
    const shouldDisplayEwalletReloader = accountPaymentType === 'PREPAYMENT';
    const textBalance: TranslationKey =
        accountPaymentType === 'POSTPAYMENT'
            ? 'account_page.ewallet_authorized_balance'
            : 'account_page.ewallet_balance';

    const { data: customerBalanceData, isLoading: isCustomerBalanceLoading } =
        useGetCustomerBalanceQuery(
            { customerId: customerId ?? 0, token: accessToken ?? '' },
            { skip: !accessToken || !customerId, refetchOnMountOrArgChange: true },
        );

    const { logout, isLogoutLoading } = useAuthenticate();
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleGoToEwallet = () => {
        if (accessToken) {
            const ewalletUrl = `${ENV.EWALLET_URL}${brandId}/home?from=wov2&autoLoginByAccessToken=${accessToken}`;
            window.open(ewalletUrl, '_blank');
        }
    };

    return {
        customerName: customerName ?? '',
        customerBalance: computeCustomerBalance(
            customerBalanceData?.customerBalance ?? 0,
            ewalletOverdraftAmount,
            currency,
            accountPaymentType,
        ),
        shouldDisplayEwalletBalance,
        shouldDisplayEwalletReloader,
        goBack: handleGoBack,
        handleGoToEwallet,
        logout,
        isLogoutLoading,
        isCustomerBalanceLoading: shouldDisplayEwalletBalance ? isCustomerBalanceLoading : false,
        textBalance,
    };
};
