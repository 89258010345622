import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { innovorderApi } from 'services';
import storage from 'redux-persist/lib/storage';
import { brandSlice } from './brand';
import { appSlice } from './app';
import { restaurantSchedulesSlice } from './restaurantSchedules';
import { menuSlice } from './menu';
import { ordersSlice } from './orders';
import { requiredActionSlice } from './requiredAction';
import { consumptionModeListSlice } from './consumptionModeList';
import { currentCompositeProductSlice } from './currentCompositeProduct';
import { ordersStatusSlice } from './ordersStatus/ordersStatus.slice';
import { ordersStatusAcknowledgedSlice } from './ordersStatusAcknowledged/ordersStatusAcknowledged.slice';
import { ordersErrorsSlice } from './ordersErrors/ordersErrors.slice';
import { customerSlice } from './customer/customer.slice';

const persistConfig = {
    key: 'root',
    blacklist: ['api', 'ordersStatus', 'app', 'consumptionModeList'],
    storage,
};

const appPersistConfig = {
    key: 'app',
    storage,
    blacklist: ['crossSellingAlreadyTriggered', 'currentFamilyFilter', 'isDisconnected'],
};

const rootReducer = combineReducers({
    brand: brandSlice.reducer,
    app: persistReducer(appPersistConfig, appSlice.reducer),
    customer: customerSlice.reducer,
    restaurantSchedules: restaurantSchedulesSlice.reducer,
    menu: menuSlice.reducer,
    orders: ordersSlice.reducer,
    ordersStatus: ordersStatusSlice.reducer,
    ordersStatusAcknowledged: ordersStatusAcknowledgedSlice.reducer,
    ordersErrors: ordersErrorsSlice.reducer,
    requiredAction: requiredActionSlice.reducer,
    consumptionModeList: consumptionModeListSlice.reducer,
    currentCompositeProduct: currentCompositeProductSlice.reducer,
    [innovorderApi.reducerPath]: innovorderApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const configureStoreWithPreloadedState = (preloadedState?: any) =>
    configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }).concat(innovorderApi.middleware),
        preloadedState,
    });

export const store = configureStoreWithPreloadedState();
export type AppStore = typeof store;

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
