import styled from '@emotion/styled';
import { Title } from '../../../../components';

export const Container = styled.form`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.base.spacing[2]};
`;

export const PaygreenConfirmTitle = styled(Title)`
    margin-bottom: ${({ theme }) => theme.base.spacing[2]};
`;

export const ErrorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.base.spacing[2]};
`;

export const CustomerInformationContentContainer = styled.div`
    margin-top: ${({ theme }) => theme.base.spacing[2]};
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const CustomerNameContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > * {
        margin-bottom: ${({ theme }) => theme.base.spacing[1]};
    }

    margin-bottom: ${({ theme }) => theme.base.spacing[2]};
`;

export const TitleContainer = styled.div`
    display: flex;

    & > * {
        padding-right: ${({ theme }) => theme.base.spacing[1]};
    }

    & > :last-child {
        opacity: 0.5;
    }
`;
