import React from 'react';
import InitialiseThirdParties from 'core/InitialiseThirdParties';
import { Helmet } from 'react-helmet';
import { Loader } from 'components';
import { DefaultLoadingContainer } from 'components/Loader/Loader.style';
import { useTranslation } from 'react-i18next';
import WorkflowStateMachineProvider from 'hooks/useWorkflowStateMachine/WorkflowStateMachineProvider';
import { ThemeProvider } from '@emotion/react';
import { SvyProvider } from '@innovorder/savory';
import { SkeletonTheme } from 'react-loading-skeleton';
import RootRouter from './RootRouter';
import { useAppVM } from './useAppVM';
import RedirectToOrderFollowUp from './RedirectToOrderFollowUp';
import 'react-loading-skeleton/dist/skeleton.css';
import CustomerAuthGate from './CustomerAuthGate';

const App: React.FunctionComponent = () => {
    const { title, theme, themeCss, error, isError, isLoading, isInitialized } = useAppVM();
    const { t } = useTranslation();

    if (isLoading || !isInitialized) {
        return (
            <DefaultLoadingContainer>
                <Loader size={50} />
            </DefaultLoadingContainer>
        );
    }

    if (isError && error) {
        // TODO: next PR => Improve display
        return <div>{JSON.stringify(error)}</div>;
    }

    return (
        <div style={{ ...themeCss, height: '100%' }}>
            <Helmet>
                <meta
                    name="theme-color"
                    content={theme.feature.primaryColor}
                    data-testId="theme-color"
                />
                <meta name="description" content={t('metadata.description')} />
                <title>{title}</title>
            </Helmet>

            <ThemeProvider theme={theme}>
                <SkeletonTheme baseColor={theme.base.colors.neutral[100]}>
                    <SvyProvider>
                        <WorkflowStateMachineProvider>
                            <RedirectToOrderFollowUp />
                            <CustomerAuthGate />
                            <RootRouter />
                            <InitialiseThirdParties />
                        </WorkflowStateMachineProvider>
                    </SvyProvider>
                </SkeletonTheme>
            </ThemeProvider>
        </div>
    );
};

export default App;
