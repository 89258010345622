import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { AccountPaymentType } from 'services/customer/customer.type';

export const customerSliceName = 'customer';

export type CustomerState =
    | {
          customerName: string | null;
          accessToken: null;
          customerId: null;
          email: null;
          phone: null;
          accountPaymentType: null;
          ewalletOverdraftAmount: null;
      }
    | {
          customerName: string;
          accessToken: string;
          customerId: number;
          email: string;
          phone: string;
          accountPaymentType: AccountPaymentType | null;
          ewalletOverdraftAmount: number | null;
      };

const initialState: CustomerState = {
    customerName: null,
    accessToken: null,
    customerId: null,
    email: null,
    phone: null,
    accountPaymentType: null,
    ewalletOverdraftAmount: null,
};

export const customerSlice = createSlice<CustomerState, SliceCaseReducers<CustomerState>, string>({
    name: customerSliceName,
    initialState,
    reducers: {
        updateCustomer: (
            state,
            action: PayloadAction<CustomerState | { customerName: string }>,
        ) => ({
            ...state,
            ...action.payload,
        }),
        resetCustomer: (_state, _action: PayloadAction) => ({ ...initialState }),
    },
});

export const getCustomer = (state: RootState) => state.customer;
export const getCustomerName = (state: RootState) => getCustomer(state).customerName;
export const getAccessToken = (state: RootState) => getCustomer(state).accessToken;
export const getCustomerId = (state: RootState) => getCustomer(state).customerId;
export const getEmail = (state: RootState) => getCustomer(state).email;
export const getPhone = (state: RootState) => getCustomer(state).phone;
export const getAccountPaymentType = (state: RootState) => getCustomer(state).accountPaymentType;
export const getEwalletOverdraftAmount = (state: RootState) =>
    getCustomer(state).ewalletOverdraftAmount;

export const { updateCustomer, resetCustomer } = customerSlice.actions;
