"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PosVersion = void 0;
var PosVersion = /** @class */ (function () {
    function PosVersion(version) {
        this.parts = this.parseVersion(version);
    }
    PosVersion.prototype.isGreaterOrEqual = function (version) {
        var versionToCompare = this.parseVersion(version);
        for (var i = 0; i < versionToCompare.length; i++) {
            var position = this.getPosition(versionToCompare[i], i);
            if (position === 1) {
                return true;
            }
            if (position === -1) {
                return false;
            }
        }
        return true;
    };
    PosVersion.prototype.getPosition = function (subVersionToCompare, index) {
        if (!this.parts[index] || !this.parts[index].length) {
            return -1;
        }
        for (var i = 0; i < subVersionToCompare.length; i++) {
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(this.parts[index][i]) || subVersionToCompare[i] > this.parts[index][i]) {
                return -1;
            }
            if (subVersionToCompare[i] < this.parts[index][i]) {
                return 1;
            }
        }
        return 0;
    };
    // eslint-disable-next-line class-methods-use-this
    PosVersion.prototype.parseVersion = function (version) {
        return version.split('.').map(function (parts) {
            return parts.split('_').map(function (part) {
                return parseInt(part, 10);
            });
        });
    };
    PosVersion.getLowestVersion = function (versions) {
        if (!versions.length) {
            return '';
        }
        return versions.reduce(function (lowest, current) {
            var posVersion = new PosVersion(lowest);
            return posVersion.isGreaterOrEqual(current) ? current : lowest;
        }, versions[0]);
    };
    return PosVersion;
}());
exports.PosVersion = PosVersion;
